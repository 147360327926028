<template>
    <div>
        <DataTable
            :value="intervalPeriods"
            :paginator="true"
            :class="{ showFilters: showFilters }"
            class="p-datatable-customers p-datatable-striped"
            :rowsPerPageOptions="rowsPerPageOptions"
            :rows="20"
            paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
            :currentPageReportTemplate="`Показана с {first} по {last} всего ${totalRecords}`"
            :totalRecords="totalRecords"
            dataKey="id"
            :globalFilterFields="['global','attributes.activeFrom','attributes.activeUntil','status']"
            :filters="filters"
            stripedRows
        >
            <template #header>
                <div class="table-header p-d-flex p-jc-md-between p-flex-wrap">
                    <div class="p-col-7 p-p-0 p-d-flex p-ai-center p-mt-2"></div>
                    <div class="p-col-5 p-p-0 p-d-flex p-ai-center">
                        <div class="p-inputgroup p-p-0 p-d-flex">
                            <span class="p-float-label">
                                <InputText type="text" v-model="filters['global']" placeholder="Поиск" />
                            </span>
                        </div>
                        <div class="p-p-0">
                            <Button @click="showCreateIntervals" class="p-button p-ml-3"> Добавить </Button>
                        </div>
                    </div>
                </div>
            </template>
            <template #empty> Периодов не найдено.</template>
            <template #loading> Загрузка списка периодов. Пожалуйста, подождите. </template>
            <Column header="Наименование периода" field="attributes.name" :sortable="true" filterMatchMode="contains">
                <template #body="slotProps">
                    {{ slotProps.data.attributes.name || 'Наименование периода' }}
                </template>
                <template #filter>
                    <InputText v-show="showFilters" type="text" v-model="filters['attributes.name']" class="p-column-filter" placeholder="Наименование" />
                </template>
            </Column>
            <Column header="Дата начала" field="attributes.activeFrom" :sortable="true" filterMatchMode="contains">
                <template #body="slotProps">
                    {{ slotProps.data.attributes.activeFrom || 'Дата начала' }}
                </template>
                <template #filter>
                    <InputText v-show="showFilters" type="text" v-model="filters['attributes.activeFrom']" class="p-column-filter" placeholder="Дата начала" />
                </template>
            </Column>
            <Column header="Дата окончания" field="attributes.activeUntil" :sortable="true" filterMatchMode="contains">
                <template #body="slotProps">
                    {{ slotProps.data.attributes.activeUntil || 'Дата окончания' }}
                </template>
                <template #filter>
                    <InputText v-show="showFilters" type="text" v-model="filters['attributes.activeUntil']" class="p-column-filter" placeholder="Дата окончания" />
                </template>
            </Column>
            <Column header="Статус" headerStyle="width: 10%; text-align: center" bodyStyle=" text-align: center; padding: 0.5rem 1rem;" field="attributes.status" :sortable="true">
                <template #body="slotProps">
                    <div class="status" :class="{ activeStatus: slotProps.data.attributes.status == 'активная', noActiveStatus: slotProps.data.attributes.status == 'неактивная' }">{{ slotProps.data.attributes.status }}</div>
                </template>
                <template #filter>
                    <Dropdown v-show="showFilters" v-model="filters['attributes.status']" :options="statuses" placeholder="Статус" class="p-column-filter" :showClear="true">
                        <template #option="slotProps">
                            <span :class="'customer-badge status-' + slotProps.option">{{ slotProps.option }}</span>
                        </template>
                    </Dropdown>
                </template>
            </Column>
            <Column header="" headerStyle="width: 10%" bodyStyle="position: relative">
                <template #body> </template>

                <template #filter>
                    <Button icon="pi pi-filter " :class="filterClasses" class="p-button-rounded p-button-outlined filterPosition" @click="showFilters = !showFilters" />
                </template>
            </Column>
            <template #paginatorLeft>
                <div class="p-col-6"></div>
            </template>
        </DataTable>
        <Dialog :visible.sync="createIntervals" position="center" :modal="true" :closeOnEscape="true" :closable="true" style="width: 400px" class="customDialog">
            <template #header>
                <h3 class="p-mb-0 customTitle p-px-2">Формирование периода</h3>
            </template>
            <div class="p-d-flex p-col-12 p-lg-12 p-mb-lg-0 p-py-0 p-px-2">
                <div class="p-field p-d-flex p-flex-column p-col-12 p-md-12 p-px-0">
                    <label for="calendarEnd">Дата окончания*</label>
                    <Calendar id="calendarEnd" v-model="dateEnd" :showIcon="true" dateFormat="dd.mm.yy" :locale="rusCalendareLocale" />
                </div>
            </div>
            <template #footer>
                <div class="p-d-flex p-jc-start">
                    <Button class="p-button p-mr-3" @click="generateIntervals" type="button" :disabled="!dateEnd"> <span class="p-button-label">Сформировать </span> </Button>
                    <Button class="p-button p-button-outlined" @click="hideCreateIntervals">
                        <span class="p-button-label black">Отменить</span>
                    </Button>
                </div>
            </template>
        </Dialog>
    </div>
</template>

<script>
import {generatePeriodIntervals, getAllIntervalPeriods } from '@/api/form/formsPeriods';
import {mapGetters} from 'vuex';
import {requestToastHandler} from '@/main/mixins';
import {rusCalendareLocale} from '@/main/calendareLocales';
import { date } from '@/main/globalFilters';

export default {
    name: 'periods',
    props: {
        loading: {
            type: Boolean,
            require: false,
        },
    },
    mixins: [requestToastHandler],
    emits: ['loadingChange'],
    data() {
        this.rusCalendareLocale = rusCalendareLocale;

        return {
            selectedPeriod: null,
            showFilters: false,
            filters: {},
            intervalPeriods: [],
            createIntervals: false,
            dateStart: null,
            dateEnd: null,
            currentPage: 1,
            totalRecords: null,
            currentPeriodId: null,
            statuses: ['активная', 'неактивная'],
        };
    },
    methods: {
        showCreateIntervals() {
            this.createIntervals = true;
        },
        hideCreateIntervals() {
            this.dateStart = null;
            this.dateEnd = null;
            this.createIntervals = false;
        },

        async getPeriods(periodicityId) {
            try {
                this.$emit('loadingChange', true);
                const { data: allIntervalPeriods, meta } = await getAllIntervalPeriods({ intervalId: periodicityId });
                this.intervalPeriods = allIntervalPeriods.map((interval) => {
                    interval.attributes.activeFromTimeStamp = this.generateTimestamp(interval.attributes.activeFrom);
                    interval.attributes.activeFrom = date(interval.attributes.activeFromTimeStamp);
                    interval.attributes.activeUntilTimeStamp = this.generateTimestamp(interval.attributes.activeUntil);
                    interval.attributes.activeUntil = date(interval.attributes.activeUntilTimeStamp);
                    interval.attributes.status = interval.attributes.active ? 'активная' : 'неактивная';
                    return interval;
                });

                this.totalRecords = meta.pagination.total;
            } catch (error) {
                this.$requestError(error.message);
            } finally {
                this.$emit('loadingChange');
            }
        },
        async generateIntervals() {
            try {
                this.$emit('loadingChange', true);
                const data = {
                    data: {
                        attributes: {
                            // from: this.dateStart,
                            until: this.dateEnd,
                        },
                    },
                };
                const result = await generatePeriodIntervals(this.periodicityId, data);
                this.hideCreateIntervals();
                if (result) this.getPeriods(this.periodicityId);
                this.$emit('loadingChange', false);
            } catch (error) {
                this.$requestError(error.message);
                this.hideCreateIntervals();
                this.$emit('loadingChange', false);
                return;
            }
        },
        generateTimestamp(dateValue) {
            const currentTimeZoneOffset = (new Date()).getTimezoneOffset() * 60000;
            return new Date(new Date(dateValue.substring(0, 10)).getTime() - currentTimeZoneOffset).toISOString().substring(0, 10);
        },
    },
    computed: {
        ...mapGetters(['rowsPerPageOptions']),
        periodicityId() {
            return this.$route.params.periodicityId || null;
        },
        filterClasses() {
            return this.showFilters ? 'mainBackground' : '';
        },
    },

    async mounted() {
        await this.getPeriods(this.periodicityId);
    },
};
</script>

<style lang="scss" scoped>
.status {
    border-radius: 4px;
    padding: 4px;
    max-width: 64px;
    color: #272727;
    font-size: 13px;
    line-height: 16px;
    font-style: normal;
    font-weight: normal;
    margin: 0 auto;
}
.activeStatus {
    background-color: #a1e5c3;
}
.noActiveStatus {
    background-color: #d1d1d1;
    max-width: 78px;
}
.customTitle {
    font-size: 17px;
}
::v-deep .p-dialog-content {
    overflow: visible;
    padding-bottom: 0;
}
::v-deep .customDialog {
    max-width: 480px;
}
::v-deep .p-datatable-thead {
    position: relative;

    // .mainBackground {
    //     background-color: #388e3c;
    //     color: white;
    //     outline: none;
    //     border: none;
    // }
    .filterPosition {
        position: absolute;
        width: 24px;
        height: 24px;
        right: 17px;
        top: 11px;
        background-color: #fff;
    }
    tr:last-child {
        th:last-child {
            text-align: right;
        }
    }
    .p-filter-column {
        padding: 0;
        background-color: #fff !important;
        input {
            min-width: 98%;
            max-width: 98%;
        }
        .p-dropdown-clearable {
            max-width: 98%;
            margin: 5px 15%;
        }
    }
}
::v-deep .p-datatable {
    .p-datatable-tbody > tr > td {
        border: none;
    }
}
</style>
